import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Modal, Spin } from "antd";
import "../PoolModal/modal.css";
import InputCommon from "../../../components/Common/Input";
import {
  BalanceHeading,
  ModalStakeBalance,
  ModalStakeButton,
  ModalStakeContainer,
  ModalStakeContract,
  ModalStakeHeading,
  ModalStakeOption,
  ModalStakeWrapper,
} from "./styled";
import SelectCommon from "../../../components/Common/Select";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useAccount, useSignMessage } from "wagmi";
import { useSelector } from "react-redux";
import { formatNumberMega } from "../../../utils/formatNumber";
import toast from "react-hot-toast";
import Web3 from "web3";
import abiTokenDefault from "../../../contract/ABI/abiJoinPool.json";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import { abiToken, lpABI } from "../../../contract/Pair";
import {
  addressContractAddLiquidity,
  addressContractLP,
  addressContractToken,
  addressContractUSDT,
} from "../../../contract/Address";
import { ethers } from "ethers";
import getTokenInfo from "../../../utils/checkInfoByAccount";
declare const window: Window & typeof globalThis & { ethereum: any };

const JOINPOOLSHARE = gql`
  mutation joinPoolShare(
    $address: String!
    $timeLimit: Int!
    $message: String!
    $signature: String!
  ) {
    joinPoolShare(
      address: $address
      timeLimit: $timeLimit
      signature: $signature
      message: $message
    )
  }
`;

const AUTOJOINPOOLSHARE = gql`
  mutation setAutoJoinPoolShare(
    $address: String!
    $auto: Boolean!
    $timeLimit: Int!
    $message: String!
    $signature: String!
  ) {
    setAutoJoinPoolShare(
      address: $address
      auto: $auto
      timeLimit: $timeLimit
      signature: $signature
      message: $message
    )
  }
`;

const GET_WALLET = gql`
  query wallet($address: String!) {
    wallet(address: $address) {
      id
      address
      oni {
        balance
      }
      usdt {
        balance
      }
      oniRewardEquity {
        balance
      }
      densityRewardEquity
      totalJoinRewardEquityUSDT
      feeWithdrawONI
      feeWithdrawUSDT
      totalJoinPoolShare
      totalLeavePoolShare
      autoJoinPoolShare
      totalRewardPoolShare
      totalReward
      totalRewardStaking
      totalRewardOnReward
      totalWithdraw
      totalStaking
      totalNetworkStaking
      totalLevelCommission
      autoJoinPoolShareTimeLimit
      oniPoolShare {
        balance
      }
      totalDirectCommission
      refCode
      maxout
      level
      investments {
        id
        address
        amount
        createdAt
        hash
      }
      transactions {
        id
        transactionType
        walletType
        amount
        fee
        note
        createdAt
        hash
        status
      }
      poolShares {
        id
        address
        amount
        amountAvlbLeave
        amountLeave
        timeLimit
        type
        manualStatus
        createdAt
        expiredAt
      }
    }
  }
`;

const ModalStake = ({
  isModalOpenStake,
  handleCancelStake,
  setLoadingData,
}: any) => {
  const [active, setActive] = useState(12);
  const [dataSelect, setDataSelect] = useState(1);
  const [loadingStake, setLoadingStake] = useState(false);
  const [joinPoolShare] = useMutation(JOINPOOLSHARE);
  const [autoKoinPoolShare] = useMutation(AUTOJOINPOOLSHARE);
  const { address } = useAccount();
  const { data: signature, signMessage, error }: any = useSignMessage();
  const [valueInput, setValueInput] = useState<any>();
  const [valueCheckbox, setValueCheckbox] = useState(false);
  const verifyMessage = "Verify";
  const { userProfile }: any = useSelector((state: any) => state.user);
  const [disableButton, setDisableButton] = useState(false);
  const web3 = new Web3(window.ethereum);
  const contractTokenFrom: any = new web3.eth.Contract(
    abiTokenDefault as any,
    "0x4a654cF6a25f00A243c754C7cB0b9c19c462f6dC"
  );
  const [oniBanlance, setOniBalance] = useState<any>(0);

  //get wallet
  const [getWallet, { data }] = useLazyQuery(GET_WALLET, {
    fetchPolicy: "network-only",
  });

  const onChangeInput = (e: any) => {
    setValueInput(e.target.value);
  };

  const onChangeSelect = (e: any, data: any) => {
    if (data.key === "0") {
      setDataSelect(1);
    } else if (data.key === "1") {
      setDataSelect(2);
    } else {
      setDataSelect(3);
    }
  };

  const onChange = (e: any) => {
    setValueCheckbox(e.target.checked);
  };

  // check value checkbox
  useEffect(() => {
    if (userProfile?.wallet) {
      if (
        userProfile?.wallet?.autoJoinPoolShare === 1 &&
        userProfile?.wallet?.autoJoinPoolShareTimeLimit === active
      ) {
        setValueCheckbox(true);
        setDisableButton(false);
      } else if (
        userProfile?.wallet?.autoJoinPoolShare === 1 &&
        userProfile?.wallet?.autoJoinPoolShareTimeLimit !== active
      ) {
        setValueCheckbox(false);
        setDisableButton(true);
      }
    }
  }, [active, userProfile]);

  //check active mount
  useEffect(() => {
    if (dataSelect === 1 && userProfile?.wallet?.autoJoinPoolShare === 1) {
      if (
        userProfile?.wallet?.autoJoinPoolShare === 1 &&
        userProfile?.wallet?.autoJoinPoolShareTimeLimit === 6
      ) {
        setActive(6);
      } else if (
        userProfile?.wallet?.autoJoinPoolShare === 1 &&
        userProfile?.wallet?.autoJoinPoolShareTimeLimit === 12
      ) {
        setActive(12);
      }
    }
  }, [userProfile, dataSelect]);

  //handle active
  const handleActive = (id: number) => {
    setActive(id);
  };

  //signal
  const handleVerifyWallet = async () => {
    setLoadingStake(true);
    await signMessage({ message: verifyMessage });
  };

  //api joinPool
  const handleJoinPool = async () => {
    try {
      const res = await joinPoolShare({
        variables: {
          address,
          // amount: userProfile?.wallet?.oni?.balance,
          timeLimit: active === 6 ? 6 : 12,
          signature,
          message: "Verify",
        },
      });
      if (res) {
        setValueInput("");
        toast.success("Success");
        handleCancelStake();
        setActive(6);
        await getWallet({ variables: { address } });
        setLoadingData(true);
        setLoadingStake(false);
      }
    } catch (error: any) {
      toast.error(error?.message);
      setLoadingStake(false);
      console.log(error);
    }
  };

  //api autoJoinPool
  const handleAutoJoinPool = async () => {
    try {
      const res = await autoKoinPoolShare({
        variables: {
          address,
          auto: valueCheckbox,
          timeLimit: active === 6 ? 6 : 12,
          signature,
          message: "Verify",
        },
      });
      if (res) {
        setValueInput("");
        setLoadingStake(false);
        toast.success("Success");
        handleCancelStake();
        setActive(6);
        await getWallet({ variables: { address } });
        setLoadingData(true);
      }
    } catch (error) {
      toast.error("Fail");
      setLoadingStake(false);
      console.log(error);
    }
  };

  // get Approve
  const [allowance, setAllowance] = useState<any>("");
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        "0x4a654cF6a25f00A243c754C7cB0b9c19c462f6dC",
        addressContractToken,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (address) {
      getAllowance();
    }
  }, [address]);

  // handle Approve
  const approveJoin = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      const contract = new web3.eth.Contract(abiToken, addressContractToken);
      await contract.methods
        .approve(
          "0x4a654cF6a25f00A243c754C7cB0b9c19c462f6dC",
          ethers.MaxUint256.toString()
        )
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async (res: any) => {
          await getAllowance();
          setLoadingStake(false);
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setLoadingStake(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setLoadingStake(false);
    }
  };

  //abi contract
  const handleJoinContract = async () => {
    try {
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contractTokenFrom.methods
        .joinPool(convertToWei(valueInput, 18))
        .estimateGas({
          from: address,
          to: "0x4a654cF6a25f00A243c754C7cB0b9c19c462f6dC",
          gasPrice: gasPrice,
        });
      await contractTokenFrom?.methods
        .joinPool(convertToWei(valueInput, 18))
        .send({
          from: address,
          to: "0x4a654cF6a25f00A243c754C7cB0b9c19c462f6dC",
          gas: gasEstimate,
          // gasPrice: gasPrice,
        })
        .then(async (res: any) => {
          if (res) {
            toast.success("Join Success");
            setLoadingStake(false);
            await getAllowance();
          } else {
            setLoadingStake(false);
            toast.error("Fail");
          }
        })
        .catch((err: any) => {
          console.log("err---", err);
          toast.error(err?.message);
          setLoadingStake(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingStake(false);
    }
  };

  // check approve
  const handleCheckApprove = async () => {
    setLoadingStake(true);
    if (valueInput > allowance) {
      approveJoin();
    } else {
      handleJoinContract();
    }
  };

  // check signature
  useEffect(() => {
    if (signature) {
      if (dataSelect === 1) {
        handleJoinPool();
      } else if (dataSelect === 2) {
        handleAutoJoinPool();
      }
    }
  }, [signature]);

  useEffect(() => {
    if (userProfile?.wallet && error) {
      setLoadingStake(false);
    }
  }, [error]);

  // Get balance LP
  const getBalanceLP = async () => {
    try {
      const contractLP = new web3.eth.Contract(abiToken, addressContractToken);
      await contractLP.methods
        .balanceOf(address)
        .call()
        .then((res: any) => {
          setOniBalance(convertFromWei(res, 18));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      getBalanceLP();
    }
  }, [address]);

  return (
    <Modal
      open={isModalOpenStake}
      onCancel={handleCancelStake}
      footer={false}
      className="pool-modal"
    >
      <ModalStakeContainer>
        <ModalStakeWrapper>
          <ModalStakeHeading>
            <h2 className="title-heading">Stake more</h2>
            <img
              src="/img/Dashboard/Pool/img_modal_02.png"
              width={178}
              loading="eager"
              alt="Stake more"
            />
          </ModalStakeHeading>
          <ModalStakeOption>
            <h3 className="title-option">Stake Option</h3>
            <SelectCommon
              data={[
                {
                  id: 1,
                  title: "Balance Stake",
                  img: "",
                },
                {
                  id: 2,
                  title: "Profit Stake",
                  img: "",
                },
                // {
                //   id: 3,
                //   title: "Amount Stake",
                //   img: "",
                // },
              ]}
              onChange={onChangeSelect}
              suffixIcon={
                <img
                  width={20}
                  height={20}
                  src="./img/Dashboard/Pool/icn_select.png"
                  loading="lazy"
                  alt="icon"
                />
              }
            />
            <ul className="list-mounth">
              {listButton.map((item: any, index: number) => (
                <li
                  key={index}
                  onClick={() => handleActive(item.id)}
                  className={active === item.id ? "is-acitve" : ""}
                >
                  <button>
                    <span></span>
                    {item.text}
                  </button>
                </li>
              ))}
            </ul>
            <p className="text-note">
              <span>
                You’ll receive {active === 6 ? "4" : "12"}% total unlock reward
                after {active} months.
              </span>
            </p>
          </ModalStakeOption>
          {dataSelect === 1 ? (
            <ModalStakeBalance>
              <BalanceHeading>
                {/* <span>Stake Amount</span> */}
                <span>
                  Balance:{" "}
                  {userProfile?.wallet
                    ? formatNumberMega(userProfile?.wallet?.oni?.balance)
                    : "0"}
                </span>
              </BalanceHeading>
            </ModalStakeBalance>
          ) : dataSelect === 2 ? (
            <ModalStakeBalance className="is-profit">
              {/* <h2 className="balance-heading">
                <span>Stake Amount (not available)</span>
              </h2> */}
              {/* <InputCommon
                placeHolder="Enter amount"
                onChange={onChangeInput}
              /> */}
              <Checkbox
                onChange={onChange}
                checked={valueCheckbox}
                disabled={disableButton}
              >
                Auto Stake (The amount of balance will automatically stake to
                Share Pool)
              </Checkbox>
            </ModalStakeBalance>
          ) : (
            <>
              <BalanceHeading>
                <span>
                  Balance: {oniBanlance ? formatNumberMega(oniBanlance) : "0"}
                </span>
              </BalanceHeading>
              <ModalStakeContract>
                <InputCommon
                  placeHolder="Enter amount"
                  onChange={onChangeInput}
                />
              </ModalStakeContract>
            </>
          )}
          {dataSelect === 3 ? (
            <ModalStakeButton
              onClick={handleCheckApprove}
              disabled={disableButton}
            >
              {loadingStake ? <Spin /> : "Confirm & Stake"}
            </ModalStakeButton>
          ) : dataSelect === 2 ? (
            <ModalStakeButton
              onClick={handleVerifyWallet}
              disabled={disableButton}
            >
              {loadingStake ? <Spin /> : "Confirm & Stake"}
            </ModalStakeButton>
          ) : (
            <ModalStakeButton onClick={handleVerifyWallet}>
              {loadingStake ? <Spin /> : "Confirm & Stake"}
            </ModalStakeButton>
          )}
        </ModalStakeWrapper>
      </ModalStakeContainer>
    </Modal>
  );
};

const listButton = [
  {
    id: 6,
    text: "6 Month",
  },
  {
    id: 12,
    text: "12 Month",
  },
];

export default ModalStake;
