import moment from "moment";
import TableCommon from "../../../components/Common/Table";
import { shortenAddress } from "../../../utils/addressUser";
import { BlockTable } from "../../Withdraw/styled";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumberMega } from "../../../utils/formatNumber";

const HistoryEquity = ({ userProfile }: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const filteredTransactions = (userProfile?.wallet?.transactions || []).filter(
    (transaction: any) => transaction.transactionType === "rewardEquity"
  );
  const reveseTransactions = filteredTransactions.reverse();

  const headingData = [
    {
      name: "Id",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {!isDesktop && <span className="text-heading-mobile">Id: </span>}
            {shortenAddress(data.id)}
          </p>
        );
      },
    },
    {
      name: `Time`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const createdAt = data?.createdAt;
        if (createdAt) {
          const timestamp = Number(createdAt);
          const time = moment(timestamp).format("HH:mm");
          const date = moment(timestamp).format("DD/MM/YYYY");
          const localDate = (
            <>
              <span>{date} - </span>
              <span className="time-highlight">{time}</span>
            </>
          );
          return (
            <p>
              {!isDesktop ? (
                <>
                  <span className="text-heading-mobile">Created: </span>
                  {localDate}
                </>
              ) : (
                <p className="time-transaction">{localDate}</p>
              )}
            </p>
          );
        }
      },
    },
    {
      name: `Amount`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <>
            <p
              style={{
                fontSize: !isDesktop ? "15px" : "",
                fontWeight: !isDesktop ? "400" : "",
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              {!isDesktop && (
                <span className="text-heading-mobile">Amount: </span>
              )}
              {formatNumberMega(data?.amount)}
              <img
                src="/img/Dashboard/Buy/icn_oni.png"
                width={20}
                height={20}
                loading="lazy"
                alt="ONI"
              />
            </p>
          </>
        );
      },
    },
    {
      name: `Hash`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {!isDesktop && <span className="text-heading-mobile">Hash: </span>}
            {data.hash ? (
              <a
                target="_blank"
                style={{ color: "unset" }}
                href={`https://bscscan.com/tx/${data.hash}`}
              >
                {shortenAddress(data.hash)}
              </a>
            ) : (
              "--"
            )}
          </p>
        );
      },
    },
    {
      name: `Description`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const note = data?.note || "";
        const regex = /(0x[a-fA-F0-9]{40})/;
        const parts = note.split(regex);
        return (
          <p>
            {!isDesktop && (
              <span className="text-heading-mobile">Description: </span>
            )}
            <span className="text-des" style={{ textAlign: "center" }}>
              {data.note ? data.note : "--"}
            </span>
          </p>
        );
      },
    },
  ];
  return (
    <BlockTable id="table-transaction">
      <TableCommon data={reveseTransactions} heading={headingData} />
    </BlockTable>
  );
};

export default HistoryEquity;
