import { gql } from "@apollo/client";

export const REWARD_EQUITY_MUTATION = gql`
  mutation RewardEquity($hash: String!) {
    rewardEquity(hash: $hash)
  }
`;

export const GET_WALLET = gql`
  query wallet($address: String!) {
    wallet(address: $address) {
      id
      address
      oni {
        balance
      }
      usdt {
        balance
      }
      oniRewardEquity {
        balance
      }
      densityRewardEquity
      totalJoinRewardEquityUSDT
      feeWithdrawONI
      feeWithdrawUSDT
      totalJoinPoolShare
      totalLeavePoolShare
      autoJoinPoolShare
      totalRewardPoolShare
      totalReward
      totalRewardStaking
      totalRewardOnReward
      totalWithdraw
      totalStaking
      totalNetworkStaking
      totalLevelCommission
      autoJoinPoolShareTimeLimit
      oniPoolShare {
        balance
      }
      totalDirectCommission
      refCode
      maxout
      level
      investments {
        id
        address
        amount
        createdAt
        hash
      }
      transactions {
        id
        transactionType
        walletType
        amount
        fee
        note
        createdAt
        hash
        status
      }
      poolShares {
        id
        address
        amount
        amountAvlbLeave
        amountLeave
        timeLimit
        type
        manualStatus
        createdAt
        expiredAt
      }
    }
  }
`;
