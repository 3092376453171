import { useContext, useEffect, useState } from "react";
import {
  EquityBlockInfo,
  EquityBlockTop,
  EquityContainer,
  EquityLeft,
  EquityWrapper,
} from "./styled";
import TitleCommon from "../../components/Common/Title";
import HistoryEquity from "./HistoryEquity";
import InputEquity from "./InputEquity";
import { ContextProviderWrapper } from "../../components/Context";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import BigNumber from "bignumber.js";
import { convertFixed } from "../../utils/convertNumber";
import { gql, useLazyQuery } from "@apollo/client";

// get total sell
const SETTING_TOTAL_SELL = gql`
  query GetFeeTransfer($tag: String!) {
    setting(tag: $tag) {
      tag
      config
    }
  }
`;

const Equity = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const { userProfile }: any = useSelector((state: any) => state.user);
  const { address } = useAccount();
  const usdtPrice = useSelector((state: any) => state.user.usdtPrice);
  const densityValue = Number(userProfile?.wallet?.densityRewardEquity);
  const [
    getSetting,
    { data: settingDataResponse, loading: settingLoading, error: settingError },
  ] = useLazyQuery(SETTING_TOTAL_SELL);

  useEffect(() => {
    getSetting({ variables: { tag: "totalJoinRewardEquityUSDT" } });
  }, [getSetting]);

  const ListInfo = [
    {
      title: "Your Buy",
      number: `${
        userProfile?.wallet?.totalJoinRewardEquityUSDT
          ? convertFixed(
              Number(
                BigNumber(
                  userProfile?.wallet?.totalJoinRewardEquityUSDT
                ).toFormat()
              )
            )
          : 0
      }`,
    },
    {
      title: "Your Equity",
      number: `${
        densityValue
          ? convertFixed(Number(BigNumber(densityValue).toFormat()))
          : "0"
      }%`,
    },
    {
      title: "Your ONI Reward",
      number: `${
        userProfile?.wallet?.oniRewardEquity?.balance
          ? userProfile?.wallet?.oniRewardEquity?.balance?.toFixed(2)
          : 0
      }`,
    },
  ];

  return (
    <EquityContainer>
      <EquityWrapper>
        <EquityLeft>
          <TitleCommon
            title="My Pool Equity"
            description="My top pool of potential candidates for the position"
          />
          <EquityBlockTop>
            <div className="block-top-inner">
              <div className="content-info">
                <h3 className="title-block">Total Equity</h3>
              </div>
              <div className="content-price">
                <p className="number-price">$10,000,000</p>
              </div>
            </div>
            <div className="block-top-inner">
              <div className="content-info">
                <h3 className="title-block">Total Sell</h3>
              </div>
              <div className="content-price">
                <p className="number-price">$1,500,000</p>
                <div className="block-progress">
                  <div
                    className={`${
                      convertFixed(
                        Number(
                          BigNumber(settingDataResponse?.setting?.config)
                            .div(1500000 * 100)
                            .shiftedBy(8)
                            .toFixed(2)
                        )
                      ) < 0.05
                        ? "no-padding"
                        : ""
                    } progress-detail`}
                    style={{
                      width: `${Math.min(
                        convertFixed(
                          Number(
                            BigNumber(settingDataResponse?.setting?.config)
                              .div(1500000 * 100)
                              .shiftedBy(8)
                              .toFixed(2)
                          )
                        ),
                        100
                      )}%`,
                    }}
                  ></div>
                  <span
                    className={`${
                      convertFixed(
                        Number(
                          BigNumber(settingDataResponse?.setting?.config)
                            .div(1500000 * 100)
                            .shiftedBy(8)
                            .toFixed(2)
                        )
                      ) > 30
                        ? "is-color"
                        : ""
                    } number-percent`}
                  >
                    {Math.min(
                      convertFixed(
                        Number(
                          BigNumber(settingDataResponse?.setting?.config)
                            .div(1500000 * 100)
                            .shiftedBy(8)
                            .toFixed(2)
                        )
                      ),
                      100
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>
          </EquityBlockTop>
          <EquityBlockInfo>
            {ListInfo.map((item: any, index: number) => (
              <div key={index} className="item-list">
                <h3 className="title">
                  <span>{item.title}</span>
                </h3>
                <p className="price">{item.number}</p>
              </div>
            ))}
          </EquityBlockInfo>
          {!isDesktop && (
            <InputEquity
              userProfile={userProfile}
              address={address}
              usdtPrice={usdtPrice}
              refreshSettings={() =>
                getSetting({ variables: { tag: "totalJoinRewardEquityUSDT" } })
              }
            />
          )}
          <HistoryEquity userProfile={userProfile} />
        </EquityLeft>
        {isDesktop && (
          <InputEquity
            userProfile={userProfile}
            address={address}
            usdtPrice={usdtPrice}
            refreshSettings={() =>
              getSetting({ variables: { tag: "totalJoinRewardEquityUSDT" } })
            }
          />
        )}
      </EquityWrapper>
    </EquityContainer>
  );
};

export default Equity;
