import { useEffect, useState } from "react";
import InputCommon from "../../../components/Common/Input";
import TitleCommon from "../../../components/Common/Title";
import { FormBalanceList, FormRow } from "../../Buy/styled";
import { FormWithdraw } from "../../Withdraw/styled";
import { BlockBalance, EquityConvert, EquityMax, EquityRight } from "../styled";
import { ButtonCommon } from "../../../Layout/styled";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useDebounce } from "use-debounce";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GET_WALLET, REWARD_EQUITY_MUTATION } from "../../../services/api";
import { Spin } from "antd";
import web3 from "web3";
import toast from "react-hot-toast";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import {
  addressContractEXA,
  addressContractUSDT,
} from "../../../contract/Address";
import { abiToken, equityABI } from "../../../contract/Pair";
declare const window: Window & typeof globalThis & { ethereum: any };

const SETTING_TOTAL_SELL = gql`
  query GetFeeTransfer($tag: String!) {
    setting(tag: $tag) {
      tag
      config
    }
  }
`;

const InputEquity = ({ address, usdtPrice }: any) => {
  const [valueInput, setValueInput] = useState<any>();
  const [percentage, setPercentage] = useState<number>(0);
  const [valueONI, setValueONI] = useState(0);
  const totalEquity = 1500000;
  const [debouncedValueInput] = useDebounce(valueInput, 500);
  const [rewardEquity] = useMutation(REWARD_EQUITY_MUTATION);
  const [loadingBuy, setLoadingBuy] = useState<boolean | null>(false);
  const [gasPrice, setGasPrice] = useState<any>("");
  const Web3 = new web3(window.ethereum);
  const [balanceUSDT, setBalanceUSDT] = useState<any>();
  const [
    getSetting,
    { data: settingDataResponse, loading: settingLoading, error: settingError },
  ] = useLazyQuery(SETTING_TOTAL_SELL);

  //get wallet
  const [getWallet, { data }] = useLazyQuery(GET_WALLET, {
    fetchPolicy: "network-only",
  });

  // onchangeInput
  const onChangeInput = (e: any) => {
    const inputValue = e.target.value;
    setValueInput(inputValue);
    const calculatedPercentage: any = (inputValue / totalEquity) * 100;
    setPercentage(calculatedPercentage.toFixed(2));
  };
  const { open } = useWeb3Modal();

  const handleClickPercentage = (percentage: number) => {
    if (balanceUSDT > 0) {
      const calculatedValue = (balanceUSDT * percentage) / 100;
      setValueInput(calculatedValue.toFixed(2));
    }
  };

  //handle get token oni when enter amount
  const handleCaculateOni = () => {
    if (valueInput) {
      const totalOni: any = ((valueInput * (10 / 100)) / usdtPrice)?.toFixed(2);
      setValueONI(totalOni);
    }
  };
  useEffect(() => {
    handleCaculateOni();
  }, [debouncedValueInput]);

  // Get balance LP
  const getBalanceUSDT = async () => {
    try {
      const contractLP = new Web3.eth.Contract(abiToken, addressContractUSDT);
      await contractLP.methods
        .balanceOf(address)
        .call()
        .then((res: any) => {
          const converFrom = convertFromWei(res, 18);
          setBalanceUSDT(Number(converFrom)?.toFixed(2));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      getBalanceUSDT();
    }
  }, [address]);

  // handle Buy Equity
  const handleEquity = async () => {
    setLoadingBuy(true);
    if (typeof window !== "undefined" && window.ethereum) {
      const contractFarm = new Web3.eth.Contract(
        equityABI,
        addressContractUSDT
      );

      if (!valueInput || isNaN(valueInput)) {
        throw new Error(
          "Invalid priceBnbPool value. It must be a valid number."
        );
      }
      const convertAmount: any = convertToWei(valueInput, 18);
      try {
        const gasEstimate: any = await contractFarm.methods
          .transfer("0x1caeb0E461a0c81fC6B3C8911De8b8805cCf09fa", convertAmount)
          .estimateGas({
            from: address,
            gasPrice: gasPrice.toString(),
          });
        await contractFarm.methods
          .transfer("0x1caeb0E461a0c81fC6B3C8911De8b8805cCf09fa", convertAmount)
          .send({
            from: address,
            gasPrice: gasPrice.toString(),
            gas: gasEstimate.toString(),
          })
          .then((res: any) => {
            if (res) {
              rewardEquity({
                variables: {
                  hash: res.transactionHash,
                },
              });
              setTimeout(async () => {
                getSetting({ variables: { tag: "totalJoinRewardEquityUSDT" } });
                await getWallet({ variables: { address } });
                await getBalanceUSDT();
              }, 2000);
              setValueInput(0);
              setValueONI(0);
              setLoadingBuy(false);
              toast.success("Buy Successfully");
            }
          })
          .catch((error: any) => {
            console.log("error", error);
            toast.error("buy has been rejected");
            setLoadingBuy(false);
          });
      } catch (error) {
        console.error("Error executing transaction:", error);
        toast.error("Error executing transaction");
        setLoadingBuy(false);
      }
    }
  };

  return (
    <EquityRight>
      <div className="content-heading">
        <TitleCommon
          title="Equity"
          description="The sum of all amount on my wallet"
        />
        <div className="block-actions">
          <div className="reload">
            <img
              src="/img/Dashboard/Equity/icn_reload.svg"
              width={20}
              height={20}
              loading="lazy"
              alt="Reload"
            />
          </div>
          <div className="setting">
            <img
              src="/img/Dashboard/Equity/icn_setting.svg"
              width={24}
              height={24}
              loading="lazy"
              alt="Setting"
            />
          </div>
        </div>
      </div>
      <BlockBalance>
        <h3 className="title-balance">USDT Balance</h3>
        <div className="block-number">
          <p className="number-token">${address ? balanceUSDT : 0}</p>
          {/* <p className="number-price">~$195.23</p> */}
        </div>
      </BlockBalance>
      <FormWithdraw>
        <FormRow>
          <div className="form-balane">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              className="form-title"
            >
              Equity Amount ($)
              {/* <EquityMax>MAX</EquityMax> */}
            </p>
            <div className="block-input">
              <InputCommon
                type="number"
                placeHolder="0.00"
                onChange={onChangeInput}
                value={valueInput}
              />
            </div>
            <FormBalanceList>
              {listPurchase.map((item: any, index: number) => (
                <li
                  onClick={() => handleClickPercentage(item.number)}
                  key={index}
                >
                  {item.number} %
                </li>
              ))}
            </FormBalanceList>
          </div>
        </FormRow>
        <FormRow>
          <div className="form-balane">
            <p
              style={{
                marginBottom: "10px",
              }}
              className="form-title"
            >
              Convert to
            </p>
            <EquityConvert>
              <div>
                <p>Equity (%)</p>
                <span>{percentage}</span>
              </div>
              <div>
                <p>Your ONI Reward</p>
                <span>{valueONI ? `~ ${valueONI}` : 0}</span>
              </div>
            </EquityConvert>
          </div>
        </FormRow>
        {address ? (
          <ButtonCommon
            className="green"
            onClick={() => {
              address ? handleEquity() : open();
            }}
            disabled={loadingBuy || !(valueInput > 0 && balanceUSDT > 0)}
          >
            {address ? loadingBuy ? <Spin /> : "Buy" : "Connect Wallet"}
          </ButtonCommon>
        ) : (
          <ButtonCommon
            className="green"
            onClick={() => {
              open();
            }}
          >
            Connect Wallet
          </ButtonCommon>
        )}
      </FormWithdraw>
    </EquityRight>
  );
};

const listPurchase = [
  {
    number: 25,
  },
  {
    number: 50,
  },
  {
    number: 75,
  },
  {
    number: 100,
  },
];

export default InputEquity;
