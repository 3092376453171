import styled from "styled-components";

export const HeaderBody = styled.div`
  background: #1a1a1a;
  padding: 15px 15px 5px;
  @media screen and (max-width: 767.98px) {
    padding: 10px 15px;
  }
`;
export const HeaderLogo = styled.div`
  display: block;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderNavigation = styled.ul`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #0c0e0e;
  padding: 4px;
  gap: 5px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: all 0.15s linear;
    &.active,
    &:hover {
      border-color: rgba(255, 255, 255, 0.4);
      background: rgba(40, 40, 40, 0.7);
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    a {
      padding: 10px 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5px;
    z-index: 900;
    border-radius: 0;
    justify-content: space-between;
    /* overflow: auto hidden; */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 200px;
      background: linear-gradient(
        -50deg,
        rgba(10, 255, 120, 0) 0%,
        #042312 50%
      );
      filter: blur(50px);
      pointer-events: none;
      z-index: -1;
    }
    li {
      position: relative;
    }
    .sub-menu {
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 13px;
      transition: all 0.26s ease;
      position: relative;
      padding-right: 5px;
      &:after {
        position: absolute;
        content: "";
        bottom: -2px;
        right: -13px;
        background: url("/img/Common/arrow_down_02.png") center/ 20px auto
          no-repeat;
        width: 20px;
        height: 20px;
      }
      img {
        position: relative;
        right: -4px;
      }
      &.active {
        opacity: 1;
      }
    }
    .animation-more {
      animation: flowIconMore 0.3s linear;
    }
    .animation-less {
      animation: flowIconLess 0.3s linear;
    }
    a {
      opacity: 0.5;
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      border: 0;
      &.active {
        opacity: 1;
        border: none;
        background: none;
        box-shadow: none;
        backdrop-filter: unset;
        background-blend-mode: unset;
      }
      &:hover {
        border: none;
      }
      img {
        margin-bottom: 4px;
        width: 29px;
        height: 29px;
      }
    }
  }
  @keyframes flowIconMore {
    0% {
      transform: translateX(500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes flowIconLess {
    0% {
      transform: translateX(500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  // menu antd
  .ant-menu {
    gap: 5px;
  }
  .ant-menu-overflow.ant-menu.ant-menu-root {
    background: unset;
    line-height: 1;
    .ant-menu-title-content {
      color: #939393;
      line-height: 1;
      border: 0;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .ant-menu-item,
  .ant-menu-overflow-item.ant-menu-submenu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .ant-menu-overflow-item.ant-menu-submenu,
  .ant-menu-overflow-item.ant-menu-item {
    &:after {
      content: none;
    }
  }
  .ant-menu-submenu {
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border-radius: 6px;
      transition: all 0.15s linear;
    }
    .ant-menu-title-content {
      padding-right: 28px;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-title-content {
      background: rgba(40, 40, 40, 0.7);
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.4) !important;
      border-radius: 6px;
      transition: all 0.15s linear;
      color: #fff !important;
    }
  }
  .ant-menu-overflow-item.ant-menu-item {
    &.ant-menu-item-selected {
      a {
        border: 1px solid rgba(255, 255, 255, 0.4);
      }
      .ant-menu-title-content {
        background: rgba(40, 40, 40, 0.7);
        background-blend-mode: luminosity;
        box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
        backdrop-filter: blur(50px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        border-radius: 6px;
        transition: all 0.15s linear;
        color: #fff;
      }
    }
  }
  .ant-menu-submenu-arrow {
    display: block;
    width: 20px;
    height: 20px;
    background: url("/img/Common/arrow_down_02.png") center/ 20px auto no-repeat;
    right: 5px;
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const HeaderActions = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 8px;
  background: #050505;
  @media screen and (max-width: 767px) {
    padding: 4px;
    margin-left: auto;
    margin-right: 10px;
  }
`;

//submenu Mobile
export const MenuMobile = styled.div`
  position: absolute;
  top: -70px;
  left: 50%;
  min-width: 110px;
  transform: translateX(-50%);
  background-color: #222125;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.26s ease;
  &.is-show {
    opacity: 1;
    pointer-events: auto;
  }
  .active-submenu {
    opacity: 1;
  }
`;

export const BtnMobileMenu = styled.button<{ isshow: any }>`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #bbf7c6;
  text-decoration: none;
  background: radial-gradient(
      105.75% 89.98% at 2.11% 14.58%,
      rgba(129, 238, 151, 0) 0%,
      rgba(129, 238, 151, 0.2) 100%
    ),
    rgba(35, 35, 35, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
    0 0 1px transparent, 0 0 2px transparent, 0 0 4px transparent,
    0 0 8px rgba(0, 0, 0, 0.4), 0 0 16px rgba(0, 0, 0, 0.4),
    0 0 17px rgba(0, 0, 0, 0.4), 0 0 20px rgba(0, 0, 0, 0.4),
    0 0 18px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  outline: none;
  box-sizing: border-box;
  position: relative;
  padding: 0px;
  border: 1px solid #bbf7c6;
  span {
    width: 18px;
    height: 2px;
    background-color: #81ee97;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.3s linear;
    ${({ isshow }) =>
      isshow
        ? `
        &:nth-child(1){
    transform: translateX(-50%) translateY(-50%) rotateZ(-45deg) ;
    }
    &:nth-child(2){
      opacity: 0;
    }
    &:nth-child(3){
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg) ;
    }
        `
        : `
    &:nth-child(1){
      top: 33%;
    }
    &:nth-child(3){
      top: 67%;
    }`}
  }
  display: none;
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`;
export const HeaderLinkGroup = styled.nav`
  border-radius: 10px;
  border: 1px solid var(--neutral-neutral-110, rgba(255, 255, 255, 0.1));
  background: linear-gradient(
    0deg,
    rgba(66, 66, 66, 0.2) 0%,
    rgba(32, 32, 32, 0.2) 85.71%
  );
  backdrop-filter: blur(12px);
  display: flex;
  padding: 6px;
  align-items: center;
  .active {
    border-radius: 8px;
    border: 1px solid #6b6b6b;
    background: #404040;
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    color: rgba(255, 255, 255, 1);
  }
  a {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    padding: 10px 32px;
    text-decoration: none;
  }
  @media only screen and (max-width: 1280px) {
    a {
      padding: 10px 16px;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
export const MenuContainer = styled.div<{ isshow: any }>`
  transition: background 0.3s linear;
  background: #00000000;
  position: fixed;
  height: 0px;
  bottom: unset;
  overflow: hidden;
  padding-top: 0px;
  @media only screen and (max-width: 1024px) {
    ${({ isshow }) =>
      isshow &&
      `
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: unset;
  z-index: 100;
  background: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  padding-top: 80px;
  `}
  }
`;
export const MenuBox = styled.ul<{ isshow: any }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--neutral-neutral-110, rgba(255, 255, 255, 0.1));
  background-color: rgba(0, 0, 0, 0.8);
  padding: 12px;
  opacity: ${({ isshow }) => (isshow ? 1 : 0)};
  transition: opacity 0.3s linear;
  overflow: auto;
  height: 400px;
  a {
    border-radius: 6px;
    /* border: 1.5px solid rgba(255, 204, 0, 0.12); */
    /* background: rgba(30, 30, 30, 0.54); */
    display: flex;
    padding: 12px 25px;
    gap: 10px;
    align-items: center;
    align-self: stretch;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    background-size: cover;
    span {
      height: 17px;
      path {
        fill: #909090;
      }
    }
  }
  .active {
    color: var(--Mint-Green-300, #ffffff);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset;
    background-image: url("./img/MenuItemBg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    gap: 12px;
    span {
      height: 17px;
      path {
        fill: #ffffff;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    a {
      figure {
        line-height: 1;
      }
    }
  }
`;
