/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { DashboardBody, DashboardBodyWrapper } from "./styled";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useAccount, useSignMessage } from "wagmi";
import { useEffect, useState } from "react";
import { useDispatch } from "../../redux/store";
import {
  fetchSetting,
  fetchTokenPrice,
  PoolInfo,
  USDTPrice,
  UserOverview,
} from "../../redux/slices/user";
import axios from "axios";
import LiveChat from "../../components/Common/LiveChat";

const LOGIN = gql`
  mutation connectWallet(
    $address: String!
    $signature: String!
    $refCode: String!
    $deviceInfoJSON: String
    $message: String!
  ) {
    connectWallet(
      address: $address
      message: $message
      signature: $signature
      refCode: $refCode
      deviceInfoJSON: $deviceInfoJSON
    )
  }
`;

const GET_WALLET = gql`
  query wallet($address: String!) {
    wallet(address: $address) {
      id
      address
      oni {
        balance
      }
      airdrop {
        balance
      }
      usdt {
        balance
      }
      oniRewardEquity {
        balance
      }
      densityRewardEquity
      totalJoinRewardEquityUSDT
      feeWithdrawONI
      feeWithdrawUSDT
      totalJoinPoolShare
      totalLeavePoolShare
      autoJoinPoolShare
      totalRewardPoolShare
      totalReward
      totalRewardStaking
      totalRewardOnReward
      totalWithdraw
      totalStaking
      totalNetworkStaking
      totalLevelCommission
      autoJoinPoolShareTimeLimit
      oniPoolShare {
        balance
      }
      totalDirectCommission
      refCode
      maxout
      level
      investments {
        id
        address
        amount
        createdAt
        hash
      }
      transactions {
        id
        transactionType
        walletType
        amount
        fee
        note
        createdAt
        hash
        status
      }
      poolShares {
        id
        address
        amount
        amountAvlbLeave
        amountLeave
        timeLimit
        type
        manualStatus
        createdAt
        expiredAt
      }
    }
  }
`;

const DashboardWrapper = () => {
  const { pathname } = useLocation();
  const { data: signature, signMessage }: any = useSignMessage();
  const { address } = useAccount();
  const [login] = useMutation(LOGIN);
  const verifyMessage = "Verify";
  const [refCodeUser, setRefCodeUser] = useState<any>();
  var base_url = window.location.search;
  const refCode = localStorage.getItem("refCode");

  const [getWallet, { data, error }] = useLazyQuery(GET_WALLET, {
    fetchPolicy: "network-only",
  });

  const handleVerifyWallet = async () => {
    await signMessage({ message: verifyMessage });
  };

  //check ref code
  useEffect(() => {
    if (base_url) {
      setRefCodeUser(base_url.split("?")[1]);
      if (address && refCodeUser !== address) {
        localStorage.setItem("refCode", `${refCodeUser}`);
      }
    } else {
      localStorage.setItem("refCode", "");
    }
  }, [refCodeUser, address, base_url]);

  //remove refCode
  // useEffect(()=>{
  //   if(refCode) {
  //     setTimeout(()=>{
  //       localStorage.removeItem('refCode')
  //     },30*60*1000)
  //   }
  // },[])

  const handleLogin = async () => {
    try {
      await login({
        variables: {
          address,
          signature,
          message: "Verify",
          refCode: `${refCode ? `${refCode}` : ""}`,
          deviceInfoJSON: "",
        },
      });
      await getWallet({ variables: { address } });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  useEffect(() => {
    if (!data || data === undefined) {
      handleVerifyWallet();
    }
  }, [data, address]);

  useEffect(() => {
    if (signature) {
      handleLogin();
    }
  }, [signature]);

  useEffect(() => {
    if (address) {
      getWallet({ variables: { address } });
    }
  }, [address]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (address && data && Object.keys(data).length > 0) {
      dispatch(UserOverview(data));
    }
  }, [data, address]);

  useEffect(() => {
    if (!address) {
      dispatch(UserOverview({}));
    }
  }, [address]);

  //get price token
  useEffect(() => {
    dispatch(fetchTokenPrice());
    // dispatch(fetchSetting("enableWithdrawUSDT"));
  }, [dispatch]);

  const getPoolInfo = async () => {
    try {
      const response: any = await axios.get(
        "https://api.geckoterminal.com/api/v2/networks/bsc/pools/0x5aDF0C6446A4A50a1A8951aFA6F3bF998071242d"
      );
      if (response) {
        dispatch(PoolInfo(response?.data?.data?.attributes));
      }
    } catch (error) {
      console.error("Error fetching prices:", error);
      throw error;
    }
  };

  const getUSDTPrice = async () => {
    try {
      const response: any = await axios.get(
        "https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0x3Dba4aE830896467a0A1C731686a2aD40CF76777"
      );
      if (response) {
        dispatch(
          USDTPrice(
            response?.data?.data?.attributes.token_prices[
              "0x3dba4ae830896467a0a1c731686a2ad40cf76777"
            ]
          )
        );
      }
    } catch (error) {
      console.error("Error fetching prices:", error);
      throw error;
    }
  };

  useEffect(() => {
    getUSDTPrice();
  }, []);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      getPoolInfo();
    }, 120000);

    getPoolInfo();

    return () => clearInterval(fetchInterval);
  }, []);

  return (
    <DashboardBody className={pathname === "/bot" ? "bot" : ""}>
      <DashboardBodyWrapper className={pathname === "/overview" ? "bg" : ""}>
        <LiveChat />
        <Outlet />
      </DashboardBodyWrapper>
    </DashboardBody>
  );
};

export default DashboardWrapper;
