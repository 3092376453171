import web3 from "web3";

export const convertToWei = (value, decimal) => {
  if (value && decimal) {
    if (Number(decimal) === 18)
      return web3.utils.toWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.toWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.toWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.toWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.toWei(value.toString(), "milliether");
    else return value.toWei() / 10 ** Number(decimal);
  } else {
    return "";
  }
};

export const convertFromWei = (value, decimal) => {
  try {
    if (Number(decimal) === 18)
      return web3.utils.fromWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.fromWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.fromWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.fromWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.fromWei(value.toString(), "milliether");
    else return value.toString() / 10 ** Number(decimal);
  } catch (error) {
    return 0;
  }
};

export const convertFixed = (value) => {
  if (Number(value) === 0)
    return value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else if (Number(value) > 1e3)
    return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else if (Number(value) < 1 && Number(value) >= 9e-4) return value?.toFixed(4);
  else if (Number(value) < 9e-4 && Number(value) >= 9e-7)
    return value?.toFixed(7);
  else if (Number(value) < 9e-7 && Number(value) >= 9e-11)
    return value?.toFixed(9);
  else if (Number(value) < 9e-11 && Number(value) >= 9e-15)
    return value?.toFixed(13);
  else if (Number(value) < 9e-15) {
    return value?.toExponential(2);
  } else return value.toFixed(2);
};
