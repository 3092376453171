import styled from "styled-components";
import { BlockTable, FormWithdraw } from "../Withdraw/styled";
import { ButtonCommon } from "../../Layout/styled";

export const EquityContainer = styled.div``;
export const EquityWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media screen and (min-width: 1024px) and (max-width: 1420px) {
    gap: 10px;
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`;
export const EquityLeft = styled.div`
  width: 70%;
  ${BlockTable} {
    max-width: unset;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
export const EquityBlockTop = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Number, #ceffd1);
  background: linear-gradient(
    180deg,
    rgba(66, 168, 147, 0.31) 0%,
    rgba(61, 160, 138, 0.5) 100%
  );
  padding: 23px 21px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/img/Dashboard/Equity/bg_01.png") center / cover no-repeat;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    background: url("/img/Dashboard/Equity/logo.png") center / cover no-repeat;
    width: 139px;
    height: 137px;
    z-index: -1;
  }
  .block-top-inner {
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(97, 97, 97, 0.22) 0%,
      rgba(199, 199, 199, 0.22) 100%
    );
    backdrop-filter: blur(34.75px);
    padding: 24px;
    width: 40%;
  }
  .title-block {
    color: #fff;
    font-family: Rubik;
    font-size: 15px;
    line-height: 120%;
  }
  .content-price {
    display: flex;
    align-items: center;
    gap: 7%;
  }
  .number-price {
    color: #fff;
    font-family: Rubik;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.6px;
  }
  .block-progress {
    border-radius: 8px;
    border: 1px solid #bbf7c6;
    background: radial-gradient(
        105.75% 89.98% at 2.11% 14.58%,
        rgba(129, 238, 151, 0) 0%,
        rgba(129, 238, 151, 0.2) 100%
      ),
      rgba(35, 35, 35, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
    width: 100%;
    position: relative;
    min-height: 23px;
    overflow: hidden;
    .number-percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &.is-color {
        color: #000;
      }
    }
  }
  .progress-detail {
    border-radius: 6px;
    border: 1px solid #bbf7c6;
    background: #81ee97;
    box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
      0px 8px 24px -16px rgba(0, 0, 0, 0.4);
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 7px 4px 0;
    span {
      color: var(--Mint-Green-950, #052e0f);
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      line-height: 120%; /* 14.4px */
      text-align: right;
      display: block;
    }
    &.no-padding {
      padding: 0;
      border: 0;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .block-top-inner {
      width: 50%;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .block-top-inner {
      width: calc(100% - 40px);
    }
  }
`;

export const EquityBlockInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  .item-list {
    width: calc(100% / 3 - 16px);
    border-radius: 6px;
    min-height: 103px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #44a456;
      width: 60px;
      height: 3px;
      border-radius: 10px;
    }
    &:nth-child(1) {
      background: linear-gradient(
        180deg,
        rgba(99, 255, 128, 0.19) 0%,
        rgba(53, 131, 67, 0.19) 100%
      );
    }
    &:nth-child(2) {
      background: linear-gradient(
        180deg,
        rgba(94, 233, 201, 0.28) 0%,
        rgba(53, 131, 113, 0.28) 100%
      );
    }
    &:nth-child(3) {
      background: linear-gradient(
        180deg,
        rgba(97, 97, 97, 0.22) 0%,
        rgba(199, 199, 199, 0.22) 100%
      );
    }
    .title {
      color: #fff;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.32px;
      span {
        position: relative;
        padding-right: 25px;
        &:after {
          position: absolute;
          content: "";
          top: 50%;
          margin-top: -7px;
          right: 0;
          background: url("/img/Dashboard/Equity/icn_suggest.svg") center /
            contain no-repeat;
          width: 14px;
          height: 14px;
        }
      }
    }
    .price {
      color: #fff;
      font-family: Rubik;
      font-size: 28px;
      font-weight: 500;
      line-height: 120%; /* 33.6px */
      letter-spacing: -0.56px;
      margin-top: auto;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .item-list {
      width: calc(100% - 40px);
    }
  }
`;

export const EquityRight = styled.div`
  width: 30%;
  ${ButtonCommon} {
    width: 100%;
    padding: 16px 20px;
    font-size: 16px;
  }
  ${FormWithdraw} {
    .block-input {
      min-height: 67px;
    }
  }
  .content-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    > div {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1023px) {
    width: calc(100% - 24px);
    margin-bottom: 20px;
    border: 1px solid #3d3d3d;
    padding: 12px;
    border-radius: 8px;
  }
`;
export const BlockBalance = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 87px;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(66, 168, 147, 0.61) 0%,
    #3da08a 100%
  );
  padding: 21px;
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/img/Dashboard/Equity/bg_02.png") center / cover no-repeat;
    z-index: -1;
  }
  .title-balance {
    color: #fff;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    line-height: 120%; /* 18px */
    position: relative;
    padding-left: 30px;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      margin-top: -12px;
      left: 0;
      background: url("/img/Dashboard/Equity/icn_usdt.png") center / contain
        no-repeat;
      width: 24px;
      height: 24px;
    }
  }
  .block-number {
    margin-top: auto;
  }
  .number-token {
    color: #fff;
    font-family: Rubik;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
  }
  .number-price {
    color: #f0fdf2;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.28px;
  }
`;
export const EquityConvert = styled.div`
  display: flex;
  gap: 10px;
  & > div {
    width: 50%;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #303030;
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(47px);
    &:nth-child(2) {
      span {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          top: 50%;
          margin-top: -12px;
          right: -30px;
          background: url("/img/Dashboard/Buy/icn_oni.png") center / contain
            no-repeat;
          width: 24px;
          height: 24px;
        }
      }
    }
    p {
      color: #989898;
      font-family: Rubik;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
`;
export const EquityMax = styled.button`
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(92, 92, 92, 0.7);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  color: #c7c7c7;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.15s linear;
  &:hover {
    opacity: 0.8;
  }
`;
