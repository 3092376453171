export const addressContractFarm = "0x3F200903c08100D5606c24d1F8A39Fd05A46B877";
export const addressContractLP_0 = "0x9e1475f4610426A77cc71AdB53227c78836BB314"; // LP on web
export const addressContractLP = "0x5aDF0C6446A4A50a1A8951aFA6F3bF998071242d"; // LP on pancake
export const routerContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const addressContractAddLiquidity =
  "0xe7b5B898Bc6B6111849d17186AeD2A24066afc56";
export const addressContractToken =
  "0x3Dba4aE830896467a0A1C731686a2aD40CF76777";
export const addressContractUSDT = "0x55d398326f99059fF775485246999027B3197955";
export const addressContractWBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const addressContractEXA = "0x28bcbAB87BB261Ca7666341676558F1d7CeBA30d"; //Token text
